import React from 'react'
export default function PeopleQuotes(props) {
  return (
    <div className="bg-grey-100 py-10">
      <div className="flex flex-wrap container mx-auto px-6">
        {props.quotes &&
          props.quotes.map((quote, i) => {
            return (
              <div className="w-full sm:w-1/2 lg:w-1/3 p-4" key={`quote-${i}`}>
                <div className="bg-white h-full p-8 flex flex-col">
                  <p className="text-base mb-8 font-light italic leading-relaxed">{quote.quote}</p>
                  <div className="mt-auto text-grey-700">
                    <p className="text-sm font-semibold">{quote.author}</p>
                    <p className="text-sm">{quote.jobTitle}</p>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
