import React, { useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import cx from 'classnames'
import { ArrowSimple } from '@truphone/icons'
import { ClickOutside } from '@truphone/utils'

export default function Filter(props) {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (value) => {
    setIsOpen(false)

    if (typeof props.onChange === 'function') {
      props.onChange(value)
    }
  }
  return (
    <>
      <Listbox
        as="div"
        className={cx('inline-block', props.className)}
        value={props.selected}
        onChange={(value) => handleSelect(value)}
        open={isOpen}
      >
        {() => (
          <>
            <div className="relative w-full">
              <ClickOutside onClickOutside={() => setIsOpen(false)}>
                <div
                  className="flex items-center pl-2 pr-6 py-3 cursor-pointer"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <Listbox.Button
                    className="font-semibold relative z-1 w-full text-left transition ease-in-out duration-150"
                    open={isOpen}
                  >
                    {props.selected?.name}
                    {props.selected?.count && <> ({props.selected?.count})</>}
                  </Listbox.Button>
                  <ArrowSimple className="w-4 h-4 ml-2"></ArrowSimple>
                </div>
                <div className="absolute z-30 w-full mt-1 bg-white rounded-md shadow-lg mb-11 min-w--250">
                  <Transition
                    show={isOpen}
                    leave="transition duration-100 ease-in"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="py-1 overflow-auto text-base text-left rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    >
                      {props.options &&
                        props.options.map((filter, i) => {
                          return (
                            <Listbox.Option
                              key={`filter-${i}`}
                              value={filter}
                              className={`p-2 cursor-pointer`}
                            >
                              {filter.name}
                              {filter.count && <> ({filter.count})</>}
                            </Listbox.Option>
                          )
                        })}
                    </Listbox.Options>
                  </Transition>
                </div>
              </ClickOutside>
            </div>
          </>
        )}
      </Listbox>
    </>
  )
}
