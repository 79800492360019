import React, { useState, useEffect } from 'react'
import { ArrowButton, PrimaryButton } from '@truphone/buttons'
import { listJobs } from './CareerService'
import Filter from './Filter'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function sortVacancies(arr) {
  var prev,
    sortArr = []
  arr = arr.filter((el) => el !== null)
  arr.sort()
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] !== prev) {
      sortArr.push({
        name: arr[i],
        count: 1
      })
    } else {
      sortArr[sortArr.length - 1].count++
    }
    prev = arr[i]
  }
  return sortArr
}

export default function Vacancies(props) {
  const [loading, setLoading] = useState(true)
  const [vacancies, setVacancies] = useState([])
  const [filteredVacancies, setFilteredVacancies] = useState([])

  const [locations, setLocations] = useState([])
  const [departments, setDepartments] = useState([])

  const [selectedLocation, setSelectedLocation] = useState()
  const [selectedDepartment, setSelectedDepartment] = useState()

  useEffect(() => {
    const listJobsAsync = async () => {
      let vacancies = await listJobs()
      vacancies.sort(function (a, b) {
        if (a.created_at > b.created_at) {
          return -1
        } else if (b.created_at > a.created_at) {
          return 1
        }

        return 0
      })

      if (props.graduate) {
        vacancies = vacancies.filter((x) =>
          x.department_hierarchy?.find((y) => y.name.match(/graduate/i))
        )
      }

      setVacancies(vacancies)
      setFilteredVacancies(vacancies)

      const locations = [
        { name: props.worldwide, count: vacancies.length },
        ...sortVacancies(vacancies.map((vacancy) => vacancy.location.country))
      ]
      setLocations(locations)
      setSelectedLocation(locations[0])
      setLoading(false)
    }

    listJobsAsync()
  }, [])

  useEffect(() => {
    const locationVacancies = vacancies.filter(
      (x) =>
        selectedLocation.name === props.worldwide || x.location.country === selectedLocation.name
    )
    setFilteredVacancies(locationVacancies)

    const locationDepartments = [
      { name: props.allTeams },
      ...sortVacancies(locationVacancies.map((vacany) => vacany.department))
    ]

    setDepartments(locationDepartments)
    setSelectedDepartment(locationDepartments[0])
  }, [selectedLocation])

  useEffect(() => {
    const locationAndDepartmentVacancies = vacancies.filter(
      (x) =>
        (selectedLocation.name === props.worldwide ||
          x.location.country === selectedLocation.name) &&
        (selectedDepartment.name === props.allTeams || selectedDepartment.name === x.department)
    )
    setFilteredVacancies(locationAndDepartmentVacancies)
  }, [selectedDepartment])

  const allVacanciesSliced = props.showAll ? filteredVacancies : filteredVacancies.slice(0, 3)

  if (loading) {
    return (
      <div className="bg-grey-100 pt-20">
        <div className="container mx-auto px-6 py-10">
          <h1 className="text-2xl lg:text-4xl font-normal my-4">
            <Skeleton width="25%" />
          </h1>
          <div className={`text-2xl`}>
            <Skeleton width="50%" />
          </div>

          <div className="flex flex-wrap -mx-4">
            <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-8 h-full flex flex-col">
                <Skeleton count={10} />
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-8 h-full flex flex-col">
                <Skeleton count={10} />
              </div>
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
              <div className="bg-white p-8 h-full flex flex-col">
                <Skeleton count={10} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (allVacanciesSliced.length === 0) {
    return (
      <div className="text-center py-20">
        <p className="text-2xl font-normal">{props.noVacanciesText}</p>
      </div>
    )
  }

  return (
    <div className="bg-grey-100 pt-20">
      <div className="container mx-auto px-6 py-10">
        {props.title && props.showAll && (
          <h1 className="text-2xl lg:text-4xl font-normal my-4">{props.title}</h1>
        )}
        {locations && locations.length > 0 && (
          <div className={`text-2xl ${props.showAll ? 'inline-block' : 'text-center'}`}>
            {props.latestVacancies}:{' '}
            <Filter
              options={locations}
              selected={selectedLocation}
              onChange={(value) => setSelectedLocation(value)}
            />
          </div>
        )}
        {departments && departments.length > 0 && props.showAll && (
          <div className={`text-2xl ${props.showAll ? 'inline-block' : 'text-center'}`}>
            {props.inText}{' '}
            <Filter
              options={departments}
              selected={selectedDepartment}
              onChange={(value) => setSelectedDepartment(value)}
            />
          </div>
        )}

        {allVacanciesSliced && (
          <div className="flex flex-wrap -mx-4">
            {allVacanciesSliced.map((vacancy, i) => {
              return (
                <div key={`vacancy-${i}`} className="w-full sm:w-1/2 lg:w-1/3 p-4">
                  <a
                    href={`${props.applyCta?.href}?vid=${vacancy.shortcode}`}
                    className="bg-white p-8 h-full flex flex-col hover:shadow"
                  >
                    <p className="overline-muted uppercase mb-4">{vacancy.department}</p>
                    <h3 className="text-2xl mb-2">{vacancy.title}</h3>
                    <p className="mb-4">{vacancy.location.location_str}</p>

                    <p className="mb-8">
                      {new Date(vacancy.created_at).toLocaleDateString('en-GB')}
                    </p>

                    {props.applyCta && (
                      <ArrowButton
                        href={`${props.applyCta.href}?vid=${vacancy.shortcode}`}
                        title={props.applyCta.title}
                        target={props.applyCta.openTab ? '_blank' : ''}
                        className={`text-lg font-semibold text-blue-100 mt-auto`}
                      >
                        {props.applyCta.text}
                      </ArrowButton>
                    )}
                  </a>
                </div>
              )
            })}
          </div>
        )}

        {props.viewAllCta && !props.showAll && (
          <div className="flex justify-center">
            <PrimaryButton
              className={`mt-10 mb-10 cursor-pointer`}
              href={props.viewAllCta.href}
              title={props.viewAllCta.title}
              target={props.viewAllCta.openTab ? '_blank' : ''}
            >
              {props.viewAllCta.text}
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  )
}
