import React, { useEffect, useState } from 'react'
import { getJob } from './CareerService'
import { Helmet } from 'react-helmet'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { PrimaryButton } from '@truphone/buttons'

export default function VacancyDetails(props) {
  const [loading, setLoading] = useState(true)
  const [jobDetails, setJobDetails] = useState(null)

  useEffect(() => {
    const getJobAsync = async () => {
      let vid = props.vid
      const params = new URLSearchParams(window.location.search)
      if (params.has('vid')) {
        vid = params.get('vid')
      }

      if (vid) {
        const jobDetails = await getJob(vid)
        if (jobDetails) {
          setJobDetails(jobDetails)
          setLoading(false)
        }
        return
      }

      //if we get here then vid is missing or invalid
      window.location = props.redirectUrl
    }

    getJobAsync()
  }, [])

  if (loading) {
    return (
      <div className="pt-36 lg:pt-40 pb-10 max-w max-w-790 mx-auto px-4 lg:px-0">
        <h1 className="text-2xl lg:text-4xl mb-6 lg:mb-8">
          <Skeleton />
        </h1>
        <h2 className="text-base overline-muted uppercase mb-6 lg:mb-8">
          <Skeleton width="20%" />
        </h2>
        <div className="text-sm lg:text-base">
          <Skeleton width="20%" />

          <Skeleton count={3} width="30%" />
          <p>
            <span className="text-grey-700">
              <Skeleton width="20%" />
            </span>
          </p>

          <div className="text-sm lg:text-base my-10 p-margin list-disc">
            <Skeleton count={15} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Helmet title={jobDetails && `${jobDetails.title} | Truphone Careers`} />
      <div className="pt-36 lg:pt-40 pb-10 max-w max-w-790 mx-auto px-4 lg:px-0">
        {jobDetails.title && (
          <h1 className="text-2xl lg:text-4xl mb-6 lg:mb-8 leading-tight">{jobDetails.title}</h1>
        )}
        {jobDetails.department && (
          <h2 className="text-base overline-muted uppercase mb-6 lg:mb-8">
            {jobDetails.department}
          </h2>
        )}
        <div className="text-sm lg:text-base">
          <p>
            {jobDetails.location && <span className="text-grey-700">{props.locationText}: </span>}
            {jobDetails.location.city && <>{loading ? <Skeleton /> : jobDetails.location.city}, </>}
            {jobDetails.location.region && <>{jobDetails.location.region}, </>}
            {jobDetails.location.country}
          </p>

          {jobDetails.created_at && (
            <p>
              <span className="text-grey-700">{props.postedDateText}: </span>
              {new Date(jobDetails.created_at).toLocaleDateString()}
            </p>
          )}
          {jobDetails.employment_type && (
            <p>
              <span className="text-grey-700">{props.typeText}: </span>
              {jobDetails.employment_type}
            </p>
          )}
        </div>

        {jobDetails.full_description && (
          <div
            className="text-sm lg:text-base my-10 p-margin list-disc"
            dangerouslySetInnerHTML={{ __html: jobDetails.full_description }}
          />
        )}

        {jobDetails.url && (
          <PrimaryButton
            href={jobDetails.application_url}
            className={'w-full sm:w-auto text-lg font-semibold mt-2 mb-1'}
          >
            {props.applyText}
          </PrimaryButton>
        )}
        {props.disclaimerText && <p className="text-sm text-grey-700">{props.disclaimerText}</p>}
      </div>
    </>
  )
}
