import React from 'react'
export default function Values(props) {
  return (
    <div className="bg-grey-100">
      <div className="container py-10 px-4 lg:px-6 mx-auto flex flex-wrap justify-center">
        {props.values &&
          props.values.map((value, i) => {
            return (
              <div className="w-1/2 sm:w-1/3 md:w-1/5 text-center" key={`value-${i}`}>
                <div className="mx-auto bg-white h-16 p-2 w-16 rounded-full shadow my-4 flex items-center justify-center">
                  <img src={value.icon.url} alt={value.icon.alt} />
                </div>
                <p className="uppercase text-sm lg:text-base font-semibold mb-4">{value.title}</p>
              </div>
            )
          })}
      </div>
    </div>
  )
}
