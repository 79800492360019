const url = 'https://web.truphone.com/api/jobs/'

async function listJobs() {
  const response = await fetch(url)
  if (response.ok) {
    const payload = await response.json()
    return payload?.jobs || []
  }

  return []
}

async function getJob(id) {
  const response = await fetch(url + id)
  if (response.ok) {
    return await response.json()
  }

  return {}
}

export { listJobs, getJob }
